import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import './layout.css';

const Layout = ({ children }) => {
  return (
    <>
      <div className="main-container">
        <main>{children}</main>
        <footer>
          © {new Date().getFullYear()}, <Link to="/legal">Legal Info</Link>
        </footer>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
